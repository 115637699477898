<template>
  <div class="details" v-if="isshow">
    <div class="top">
      <div class="topline"></div>
      <div class="topmsg">
        <div class="plateno">
          <div class="icon"><img src="../../../assets/iconcar.png" /></div>
          <div class="num">{{ ordermsgList.data[0].plateNo }}</div>
        </div>
        <div class="cash">
          <div class="money">
            <i style="font-size: 20px">￥</i>
            {{ (ordermsgList.totalAmount * 1) | rounding }}
          </div>
          <div class="parkname">{{ ordermsgList.data[0].parkingName }}</div>
        </div>
      </div>
      <div class="top-foot">
        <div class="leftpoint"></div>
        <div class="line"></div>
        <div class="rightpoint"></div>
      </div>
      <div class="details-list">
        <div class="details-title">
          <div>
            费用详情
            <i style="font-weight: none">
              （共{{ ordermsgList.data.length }}笔）</i
            >
          </div>
        </div>
        <div>
          <div
            class="details-msg"
            v-for="(item, index) in ordermsgList.data"
            :key="index"
          >
            <div class="msgtext">
              <div class="message">
                <div class="msgtitle">应收金额</div>
                <div style="color: #2370ff">
                  ￥{{ (item.amount * 1) | rounding }}
                </div>
              </div>
              <div class="message">
                <div class="msgtitle">停放泊位</div>
                <div style="color: #333333">{{ item.stallNo }}</div>
              </div>
              <div class="message">
                <div class="msgtitle">停车时长</div>
                <div style="color: #333333">{{ item.parkTime }}分钟</div>
              </div>
              <div class="message">
                <div class="msgtitle">驶入时间</div>
                <div style="color: #333333">{{ item.inTime }}</div>
              </div>
              <div class="message">
                <div class="msgtitle">驶出时间</div>
                <div style="color: #333333">{{ item.outTime }}</div>
              </div>
            </div>
            <div class="msgline"></div>
          </div>
        </div>
      </div>
      <div class="foot-line"></div>
    </div>
    <div class="other-foot">
      <div>
        合计：<i style="color: #ed3f3f"
          >￥{{ (ordermsgList.totalAmount * 1) | rounding }}</i
        >
      </div>
      <el-button
        class="allpay"
        @click="payhandel"
        :disabled="ordermsgList.totalAmount !== 0 ? false : true"
        :style="{
          backgroundColor: ordermsgList.totalAmount !== 0 ? '#2370ff' : '#ccc',
        }"
      >
        支付
      </el-button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "Details",
  data() {
    return {
      isshow: false,
      domain: process.env.VUE_APP_TESTLOCAL_DOMAIN,
      appId: localStorage.getItem("appId"),
      itemId: localStorage.getItem("itemId"),
      plateNo: null,
      openId: null,
      chan: localStorage.getItem("chan"),
      ordermsgList: {},
    };
  },
  mounted() {
    let { appId, itemId, chan } = this;
    this.$axios
      .post(`${this.domain}/api/parkPay/gainOrders`, {
        itemId,
        chan,
      })
      .then((res) => {
        if (res.data.data !== null) {
          if (res.data.totalAmount === 0) {
            this.ordermsgList = res.data;
            this.isshow = true;
            this.$alert(`当前处于免费时段，无需支付可直接离场`, "提示")
              .then(() => {
                return;
              })
              .catch(() => {
                return;
              });
          } else {
            this.ordermsgList = res.data;
            this.isshow = true;
          }
        } else {
          this.$alert(`暂无此车辆信息`, "提示")
            .then(() => {
              return;
            })
            .catch(() => {
              return;
            });
        }
      });
  },
  methods: {
    payhandel: _.debounce(function () {
      let openId = localStorage.getItem("openId");
      let userId = localStorage.getItem("alipayuserId");
      let { appId, itemId, chan } = this;
      if (openId) {
        this.$axios
          .post(`${this.domain}/api/pay/wxRefineOrder`, {
            openId,
            appId,
            itemId,
            chan,
            color: this.ordermsgList.data[0].plateColor,
            plate: this.ordermsgList.data[0].plateNo,
            amount: this.ordermsgList.totalAmount,
          })
          .then((res) => {
            let data = res.data.data;
            this.weixinPay(data);
          });
      }
      if (userId) {
        this.$axios
          .post(`${this.domain}/api/pay/zfbRefineOrder`, {
            userId,
            appId,
            itemId,
            chan,
            color: this.ordermsgList.data[0].plateColor,
            plate: this.ordermsgList.data[0].plateNo,
            amount: this.ordermsgList.totalAmount,
          })
          .then((res) => {
            let tradeNO = res.data.trade_no;
            this.tradePay(tradeNO);
          });
      }
    }),
    // 微信收银台
    //解决微信内置对象报错
    weixinPay(data) {
      var that = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            that.onBridgeReady(data),
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", that.onBridgeReady(data));
          document.attachEvent(
            "onWeixinJSBridgeReady",
            that.onBridgeReady(data)
          );
        }
      } else {
        that.onBridgeReady(data);
      }
    },
    // 微信内置浏览器类，weChatParameter对象中的参数是3.步骤代码中从后端获取的数据
    onBridgeReady(data) {
      WeixinJSBridge.invoke("getBrandWCPayRequest", data, (res) => {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          this.$alert(`支付成功`, "提示")
            .then(() => {
              WeixinJSBridge.call("closeWindow");
              return;
            })
            .catch(() => {
              WeixinJSBridge.call("closeWindow");
              return;
            });
        } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
          alert("支付已取消，请重新扫码");
        } else {
          this.$router.push("/inpark/fail");
        }
      });
    },
    // 支付宝收银台
    // 由于js的载入是异步的，所以可以通过该方法，当AlipayJSBridgeReady事件发生后，再执行callback方法
    ready(callback) {
      if (window.AlipayJSBridge) {
        callback && callback();
      } else {
        document.addEventListener("AlipayJSBridgeReady", callback, false);
      }
    },
    tradePay(tradeNO) {
      let that = this;
      this.ready(function () {
        // 通过传入交易号唤起快捷调用方式(注意tradeNO大小写严格)
        AlipayJSBridge.call(
          "tradePay",
          {
            tradeNO: tradeNO,
          },
          function (data) {
            if (data.resultCode === "9000") {
              AlipayJSBridge.call("closeWebview");
            } else {
              alert("支付失败，请重新扫码");
            }
          }
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}
.details {
  padding: 15px;
  background-color: #f8f8f8;
  min-height: 100vh;

  .top {
    width: 345px;
    padding-bottom: 55px;
    .topline {
      height: 20px;
      background-image: url("../../../assets/topline.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .topmsg {
      padding: 5px 0 15px 15px;
      background-color: #fff;
      .plateno {
        display: flex;
        align-items: center;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          // line-height: 20px;
          text-align: center;
          background-color: #2370ff;
          border-radius: 50%;

          img {
            width: 12px;
            height: 10px;
          }
        }

        .num {
          margin-left: 5px;
          font-size: 18px;
          color: #333333;
        }
      }

      .cash {
        margin: 15px 0;
        text-align: center;

        .money {
          font-size: 35px;
          color: #2370ff;
          font-weight: 700;
          margin-bottom: 5px;
        }

        .parkname {
          font-size: 14px;
          color: #a3a3a3;
        }
      }
    }

    .top-foot {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      height: 16px;
      background-color: #fff;

      .leftpoint {
        position: absolute;
        left: 0;
        transform: translate(-50%, 0);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #f8f8f8;
      }

      .line {
        margin: 0 auto;
        width: 300px;
        border: 1px dashed #e9e7e7;
      }

      .rightpoint {
        position: absolute;
        right: 0;
        transform: translate(50%, 0);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #f8f8f8;
      }
    }

    .details-list {
      padding: 17px 15px 15px;
      background-color: #fff;

      .details-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 15px;
      }

      .details-msg {
        .msgtext {
          margin-top: 15px;
          padding: 10px;
          background-color: #f8f8f8;
          font-size: 13px;
          line-height: 25px;

          .message {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .msgtitle {
              color: #a3a3a3;
            }
          }
        }

        .msgline {
          margin-top: 15px;
          border: 1px dashed #e5e5e5;
        }
      }
    }

    .foot-line {
      height: 20px;
      background-image: url("../../../assets/footline.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }

  .other-foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    background-color: #fff;
    font-size: 15px;
    padding-left: 15px;

    .allpay {
      display: block;
      width: 110px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: #fff;
      background-color: #2370ff;
    }
  }
}
</style>
